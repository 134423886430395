@font-face {
  font-family: 'FFMarkWebProLight';
  src: url('./fonts/FFMarkWebProLight/font.woff2') format('woff2'), url('./fonts/FFMarkWebProLight/font.woff') format('woff');
}
@font-face {
  font-family: 'FFMarkWebProLightItalic';
  src: url('./fonts/FFMarkWebProLightItalic/font.woff2') format('woff2'), url('./fonts/FFMarkWebProLightItalic/font.woff') format('woff');
}
@font-face {
  font-family: 'Mark-Pro';
  src: url('./fonts/FFMarkWebProItalic/font.woff2') format('woff2'), url('./fonts/FFMarkWebProItalic/font.woff') format('woff');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'Mark-Pro';
  src: url('./fonts/FFMarkWebProRegular/font.woff2') format('woff2'), url('./fonts/FFMarkWebProRegular/font.woff') format('woff');
  font-weight: 400;
}
@font-face {
  font-family: 'Mark-Pro';
  src: url('./fonts/FFMarkWebProMedium/font.woff2') format('woff2'), url('./fonts/FFMarkWebProMedium/font.woff') format('woff');
  font-weight: 500;
}
@font-face {
  font-family: 'Mark-Pro';
  src: url('./fonts/FFMarkWebProMediumItalic/font.woff2') format('woff2'), url('./fonts/FFMarkWebProMediumItalic/font.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: 'Mark-Pro';
  src: url('./fonts/FFMarkWebProBold/font.woff2') format('woff2'), url('./fonts/FFMarkWebProBold/font.woff') format('woff');
  font-weight: 700;
}
@font-face {
  font-family: 'Mark-Pro';
  src: url('./fonts/FFMarkWebProBoldItalic/font.woff2') format('woff2'), url('./fonts/FFMarkWebProBoldItalic/font.woff') format('woff');
  font-weight: 700;
  font-style: italic;
}

html, body, #root {
  height: 100%;
  width: 100%;
}

body {
  margin: 0;
  font-family: "Mark-Pro", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #FFFFFF;
}

#root {
  display: flex;
  flex-direction: column;
}

.header {
  flex-shrink: 0;
}

.content {
  flex: 1 0 auto;
}

.footer {
  flex-shrink: 0;
}
